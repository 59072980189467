<template>
    <div class="main-page">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container">
                    <div class="grid justify-content-between">
                        <div class="col-12 md:col-6 lg:col-5 " >
                            <div class="" >
                                <div class="flex align-items-center ">
                                    <div>
                                        <div class="text-2xl text-primary font-bold">My Account</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container">
                <div class="grid ">
                    <div class="col comp-grid" >
                        <div :class="{ 'card': !isSubPage }" class="">
                            <div >
                                <div class="relative-position" style="min-height:100px">
                                    <div class="grid">
                                        <div class="col-12">
                                            <template v-if="pageReady">
                                                <div>
                                                    <div class="p-4 mb-4">
                                                        <div class="grid align-items-center">
                                                            <div class="col-fixed" style="width:120x">
                                                                <Avatar icon="pi pi-user" size="xlarge" shape="circle" />
                                                                </div>
                                                                <div class="col">
                                                                    <div class="text-2xl text-primary"> {{ $auth.userName }} </div>
                                                                    <div class="text-sm text-gray-500"> {{ $auth.userEmail }} </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <TabView v-model:activeIndex="activeTab">
                                                            <TabPanel>
                                                                <template #header>
                                                                    <i class="pi pi-user mr-2"></i>
                                                                    <span>Account Detail</span>
                                                                </template>
                                                                <div>
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Id: </div>
                                                                            <div class="font-bold">{{ item.id }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Company: </div>
                                                                            <div class="font-bold">{{ item.company }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Name: </div>
                                                                            <div class="font-bold">{{ item.name }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Username: </div>
                                                                            <div class="font-bold">{{ item.username }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Email: </div>
                                                                            <div class="font-bold">{{ item.email }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Active: </div>
                                                                            <div class="font-bold">{{ item.active }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Datecreate: </div>
                                                                            <div class="font-bold">{{ item.datecreate }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">Role: </div>
                                                                            <div class="font-bold">{{ item.role }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                    <div class="grid align-items-center">
                                                                        <div class="col">
                                                                            <div class="text-500 text-sm mb-1">User Role Id: </div>
                                                                            <div class="font-bold">{{ item.user_role_id }}</div>
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </TabPanel>
                                                            <TabPanel>
                                                                <template #header>
                                                                    <i class="pi pi-pencil  mr-2"></i>
                                                                    <span>Edit Account</span>
                                                                </template>
                                                                <div class="reset-grid">
                                                                    <account-edit-page></account-edit-page>
                                                                    </div>
                                                                </TabPanel>
                                                                <TabPanel>
                                                                    <template #header>
                                                                        <i class="pi pi-lock  mr-2"></i>
                                                                        <span>Change Password</span>
                                                                    </template>
                                                                    <div class="reset-grid">
                                                                        <change-password-page></change-password-page>
                                                                    </div>
                                                                </TabPanel>
                                                            </TabView>
                                                        </div>
                                                    </template>
                                                    <template v-if="loading">
                                                        <div  class="q-pa-lg text-center">
                                                            <ProgressSpinner style="width:50px;height:50px" /> Loading...
                                                        </div>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
</template>
<script setup>
	import {  ref, toRefs, onMounted } from 'vue';
	import { useApp } from '@/composables/app.js';
	import { useAuth } from '@/composables/auth';
	import  AccountEditPage  from "./accountedit.vue";
	import  ChangePasswordPage  from "./changepassword.vue";
	import { useViewPage } from '@/composables/viewpage.js';
	const props = defineProps({
		id: [String, Number],
		primaryKey: {
			type: String,
			default: 'id',
		},
		pageName: {
			type: String,
			default: 'users',
		},
		routeName: {
			type: String,
			default: 'usersaccountview',
		},
		apiPath: {
			type: String,
			default: 'account',
		},
		editButton: {
			type: Boolean,
			default: true,
		},
		deleteButton: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		msgBeforeDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		msgAfterDelete: {
			type: String,
			default: "Record deleted successfully",
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		isSubPage: {
			type : Boolean,
			default : false,
		},
	});
	const app = useApp(props);
	const auth = useAuth();
	const page = useViewPage(props);
	const activeTab = ref(0)
	//page state
	const { 
		item, 
		loading, 
		pageReady, // if api data loaded successfully
	} = toRefs(page.state);
	//page computed propties
	const {
		apiUrl, // page current api path
		currentRecord, // current page record  - Object
	} = page.computedProps
	//page methods
	const { load,  deleteItem } = page.methods
	onMounted(()=>{
		const pageTitle = "My Account";
		app.setPageTitle(props.routeName, pageTitle); // set browser page title
		load();
	});
</script>
<style scoped>
</style>
